import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import classes from './FinishStep.module.scss';
import BtnBlue from '@/atoms/BtnBlue/BtnBlue';
import ValueWithCurrency from '@/atoms/ValueWithCurrency/ValueWithCurrency';
import globalClasses from '@/containers/App/Global.module.scss';
import CashIcon from '@/icons/cash.svg';
import OfficeIcon from '@/icons/office-trips.svg';
import { routes } from '@/utils/constants';
const FinishStep = ({ finishData, setFinishData }) => {
    const [lastStep, setLastStep] = useState(false);
    const intl = useIntl();
    const toLastStep = () => {
        setLastStep(true);
    };
    const finish = () => {
        setFinishData();
    };
    return (React.createElement("div", { className: classes.finish },
        React.createElement("div", { className: classes.finishHead }, intl.formatMessage({ id: 'common.finishTrip', defaultMessage: 'Finish trip' })),
        ((finishData === null || finishData === void 0 ? void 0 : finishData.paymentType) === 'CASH') && !lastStep && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classes.holder },
                React.createElement("div", { className: classes.cashBox },
                    React.createElement(CashIcon, { width: 36, height: 36 }),
                    intl.formatMessage({ id: 'common.cashPayment', defaultMessage: 'Cash payment, collect the specified amount from the client' })),
                React.createElement("div", { className: classes.costHolder },
                    React.createElement("div", { className: classes.cost },
                        React.createElement(ValueWithCurrency, { value: finishData === null || finishData === void 0 ? void 0 : finishData.cost, currencySymbol: finishData === null || finishData === void 0 ? void 0 : finishData.currency, minimumFractionDigits: true, withZero: true })))),
            React.createElement("div", { className: classes.btnBox },
                React.createElement("div", { onClick: toLastStep, className: globalClasses.bigBlueBtn }, intl.formatMessage({ id: 'common.confirmPayment', defaultMessage: 'Confirm payment' }))))),
        (lastStep || ((finishData === null || finishData === void 0 ? void 0 : finishData.paymentType) !== 'CASH')) && (React.createElement("div", { className: classes.finishHolder },
            React.createElement("div", { className: classes.finishTitle }, intl.formatMessage({ id: 'common.rideSuccessfullyCompleted', defaultMessage: 'The ride has been successfully completed' })),
            React.createElement(Link, { to: routes.officeTrips.f, className: classes.link },
                React.createElement(BtnBlue, { onClick: finish, text: intl.formatMessage({ id: 'common.returnOfficeTrips', defaultMessage: 'Return to Office Trips' }), icon: React.createElement(OfficeIcon, { width: 24, height: 24 }) }))))));
};
export default FinishStep;
