import { gql } from '@apollo/client';
export const POLL_TRIP_STATE = gql `
  query PollTripState {
    pollTripState {
      hasAssignedTrip
      driverStatus
    }
  }
`;
export const GET_ACTIVE_TRIP = gql `
  query GetActiveTrip {
    getActiveTrip {
      tripRequest {
        clientName
        clientPhone
        comment
        cost
        currency
        destinationAddress
        id
        paymentType
        pickUpAddress
        status
        createdByDriver
      }
    }
  }
`;
export const TRIP_REQUEST_ACCEPT = gql `
  mutation TripRequestAccept($tripRequestId: Uuid!) {
    tripRequestAccept(tripRequestId: $tripRequestId) {
      success
    }
  }
`;
export const TRIP_REQUEST_ARRIVE = gql `
  mutation TripRequestArrive($tripRequestId: Uuid!) {
    tripRequestArrive(tripRequestId: $tripRequestId) {
      success
    }
  }
`;
export const TRIP_REQUEST_ONBOARD = gql `
  mutation TripRequestOnboard($tripRequestId: Uuid!) {
    tripRequestOnboard(tripRequestId: $tripRequestId) {
      success
    }
  }
`;
export const TRIP_REQUEST_FINISH = gql `
  mutation TripRequestFinish($tripRequestId: Uuid!) {
    tripRequestFinish(tripRequestId: $tripRequestId) {
      success
    }
  }
`;
export const LIST_TRIP_STATIONS = gql `
  query ListTripStations($listTripStationsInput: ListTripStationsInput!) {
    listTripStations(listTripStationsInput: $listTripStationsInput) {
      items {
        id
        name
        driversCount
        address
      }
    }
  }
`;
export const SET_STATUS_WAITING_FOR_ORDER = gql `
  mutation SetStatusWaitingForOrder($setStatusWaitingForOrderInput: SetStatusWaitingForOrderInput!) {
    setStatusWaitingForOrder(setStatusWaitingForOrderInput: $setStatusWaitingForOrderInput) {
      success
    }
  }
`;
export const GET_DRIVER_STATUS = gql `
  query GetDriverStatus {
    getDriverStatus {
      stationId
      status
    }
  }
`;
export const SET_STATUS_OFFLINE = gql `
  mutation SetStatusOffline {
    setStatusOffline {
      success
    }
  }
`;
export const CREATE_TRIP_REQUEST = gql `
  mutation CreateTripRequest($createTripRequestInput: CreateTripRequestInput!) {
    createTripRequest(createTripRequestInput: $createTripRequestInput) {
      success
    }
  }
`;
export const TRIPS_STATIONS_AUTOCOMPLETE = gql `
  query TripsStationsAutocomplete($tripsStationsAutocompleteInput: TripsStationsAutocompleteInput!) {
    tripsStationsAutocomplete(tripsStationsAutocompleteInput: $tripsStationsAutocompleteInput) {
      items {
        id
        name
        address
      }
    }
  }
`;
